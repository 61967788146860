import React, { useRef, useEffect, useState } from "react"
import { Box, Button, Container, Typography } from "@mui/material"
import Confetti from "@tholman/confetti"
import PromocionCard from "./PromocionCard"
import "./Promociones.css"

const Promociones = () => {
  const estiloFondo = {
    backgroundImage: `url('/assets/img/FondoBienvenida.jpg')`,
    backgroundSize: "cover",
    minHeight: "100vh",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    color: "white",
    padding: "2rem 0",
    textAlign: "center",
    position: "relative",
  }

  const formularioGoogleUrl =
    "https://docs.google.com/forms/d/e/1FAIpQLSejj-uyjCGxZI27Fgjhr9hpv6yAWUvQX2XOalz7QMoQBdPaZA/viewform?usp=header"
  const confettiContainerRef = useRef<HTMLDivElement>(null)
  const videoRef = useRef<HTMLVideoElement>(null)
  const [confettiHeight, setConfettiHeight] = useState("auto")
  const [showButton, setShowButton] = useState(true)
  const contacts = [
    { number: "263 4286422", link: "https://wa.me/5492634286422" },
    { number: "263 4271251", link: "https://wa.me/5492634271251" },
    { number: "263 4308973", link: "https://wa.me/5492634308973" },
  ]

  const promociones = [
    "Cobertura fotográfica.",
    "Tres pistas de baile.",
    "Sector Vip egresados: DJ, Tragos y catering.",
    "Pasarela de los egresados.",
    "Espectáculo de fuegos artificiales y show de lásers.",
    "Cámaras multiplanos, dron y pantallas gigantes.",
    "Barra de tragos, juegos y gaseosas.",
    "Pileta de espuma.",
    "Video resumen de la fiesta.",
    "Cotillón para los egresados.",
    "Después de tu fiesta: Pool Party.",
  ]

  const handlePlayWithSound = () => {
    if (videoRef.current) {
      videoRef.current.muted = false
      videoRef.current
        .play()
        .catch((error) => console.error("Video playback failed:", error))
    }
    setShowButton(false)
  }

  const handleVolumeChange = () => {
    if (videoRef.current && !videoRef.current.muted) {
      setShowButton(false)
    }
  }

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener("volumechange", handleVolumeChange)
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener("volumechange", handleVolumeChange)
      }
    }
  }, [])

  useEffect(() => {
    const handleResize = () => {
      if (confettiContainerRef.current && videoRef.current) {
        const containerHeight = confettiContainerRef.current.offsetHeight
        const videoHeight = videoRef.current.offsetHeight
        const confettiMaxHeight = containerHeight - videoHeight / 1.6
        setConfettiHeight(`${confettiMaxHeight}px`)
      }
    }

    handleResize()
    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <Box sx={estiloFondo}>
      {/* Contenedor de confeti con límite de altura */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          height: confettiHeight,
          pointerEvents: "none",
          zIndex: 0,
        }}
      >
        <Confetti total={0} />
      </Box>
      <Container ref={confettiContainerRef}>
        {/* Título de la sección */}
        <Box sx={{ mb: 2 }}>
          <Typography
            gutterBottom
            sx={{
              fontWeight: 600,
              color: "#fff",
              fontFamily: "Times New Roman web",
              fontSize: 38,
            }}
          >
            EGRESADOS 2026
          </Typography>
        </Box>
        {/* Video */}
        <Box
          sx={{
            mb: 4,
            display: "flex",
            justifyContent: "center",
            maxWidth: "100%",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
            zIndex: 1,
          }}
        >
          {showButton && (
            <Button
              onClick={handlePlayWithSound}
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                background: "#900c3f",
                color: "#fff",
                fontWeight: 700,
                borderRadius: 2,
                padding: "0.5rem 1.5rem",
                boxShadow: 3,
                zIndex: 2,
                "&:hover": {
                  background: "#d6135e",
                  boxShadow: 6,
                },
              }}
            >
              ¡Activa el sonido!
            </Button>
          )}
          <Box
            component="video"
            ref={videoRef}
            controls
            autoPlay
            muted
            sx={{
              height: {
                xs: "18rem",
                md: "28rem",
              },
              margin: "0 auto",
            }}
          >
            <source src="assets/img/Egresados.mp4" type="video/mp4" />
            Tu navegador no soporta el tag de video.
          </Box>
        </Box>
        {/* Telefonos */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: -2,
            mb: 3,
            gap: 1.5,
          }}
        >
          {contacts.map((contact, index) => (
            <Box key={index}>
              <a
                href={contact.link}
                target="_blank"
                rel="noreferrer"
                className="footer-link text-white px-1"
                style={{ textDecoration: "none" }}
              >
                {" "}
                <span
                  className="d-sm-none"
                  style={{ color: "#ffffff", fontSize: "1em" }}
                >
                  <i
                    className="bi bi-whatsapp"
                    style={{ color: "#25D36699" }}
                  ></i>
                  {contact.number}
                </span>
                <span
                  className="d-none d-sm-inline"
                  style={{ color: "#ffffff", fontSize: "1.5em" }}
                >
                  <i
                    className="bi bi-whatsapp"
                    style={{ color: "#25D36699" }}
                  ></i>
                  {contact.number}
                </span>
              </a>
            </Box>
          ))}
        </Box>
        {/* Lista de promociones */}
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
            gap: 2,
          }}
        >
          {promociones.map((promo, index) => (
            <PromocionCard key={index} promo={promo} />
          ))}
        </Box>
      </Container>
    </Box>
  )
}

export default Promociones
