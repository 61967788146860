import React, { useEffect, useLayoutEffect } from "react"
import { Route, Routes, useLocation } from "react-router-dom"
import Footer from "../components/Footer/Footer"
import NavBar from "../components/NavBar/NavBar"
import Home from "../components/Home/Home"
import Page404 from "../components/Page404/Page404"
import Promociones from "../components/Promociones/Promociones"

const UserRouter = () => {
  const location = useLocation()

  useEffect(() => {
    // Guarda la posición del scroll al desmontar
    return () => {
      saveScrollPosition()
    }
  }, [])

  useLayoutEffect(() => {
    // Restaura la posición del scroll al cargar o cambiar de ruta
    restoreScrollPosition()

    // Agrega un event listener para el botón "atrás" del navegador
    const handlePopstate = () => {
      // Restaura la posición del scroll al presionar el botón "atrás"
      restoreScrollPosition()
    }

    window.addEventListener("popstate", handlePopstate)

    return () => {
      window.removeEventListener("popstate", handlePopstate)
    }
  }, [location.pathname])

  const saveScrollPosition = () => {
    if (location.pathname === "/") {
      localStorage.setItem(location.pathname, window.scrollY.toString())
    }
  }

  const restoreScrollPosition = () => {
    if (location.pathname === "/") {
      const savedPosition = localStorage.getItem(location.pathname)
      const scrollY = savedPosition ? parseInt(savedPosition, 10) : 0
      window.scrollTo(0, scrollY)
    } else {
      window.scrollTo(0, 0)
    }
  }

  return (
    <div className="d-flex flex-column min-vh-100">
      {/* Condición para mostrar la NavBar solo si no estamos en /promociones */}
      {location.pathname !== "/promociones2026" && <NavBar />}

      {/* Contenido */}
      <div className="flex-grow-1">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/promociones2026" element={<Promociones />} />{" "}
          {/* Nueva ruta */}
          {/* Ruta para página de error 404 */}
          <Route path="*" element={<Page404 />} />
        </Routes>
      </div>

      {/* Pie de página */}
      <Footer />
    </div>
  )
}

export default UserRouter
