import { Card, CardContent, Typography } from "@mui/material"
import React from "react"
import { useInView } from "react-intersection-observer"

interface PromocionCardProps {
  promo: string
}

const PromocionCard: React.FC<PromocionCardProps> = ({ promo }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  })

  return (
    <Card
      ref={ref}
      sx={{
        background: "#900c3f",
        borderRadius: "16px",
        boxShadow: 6,
        padding: 0,
        transition: "transform 0.3s ease, box-shadow 0.3s ease",
        transform: inView ? "translateY(0)" : "translateY(100px)",
        opacity: inView ? 1 : 0,
        animation: inView ? "bounceIn 0.5s ease" : "none",
        "&:hover": {
          transform: "translateY(-5px)",
          boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
        },
        "@keyframes bounceIn": {
          "0%": { transform: "scale(0.5)", opacity: 0 },
          "60%": { transform: "scale(1.05)", opacity: 1 },
          "80%": { transform: "scale(0.95)" },
          "100%": { transform: "scale(1)" },
        },
      }}
    >
      <CardContent>
        <Typography variant="h6" sx={{ fontWeight: 600, color: "#fff" }}>
          {promo}
        </Typography>
      </CardContent>
    </Card>
  )
}

export default PromocionCard
