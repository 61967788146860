import { withAuthenticationRequired } from "@auth0/auth0-react"
import { Route, Routes, useLocation } from "react-router-dom"
import Egresados from "../components/Egresados/Egresados"
import NavBar from "../components/NavBar/NavBar"
import Page404 from "../components/Page404/Page404"
import Admin from "../screens/Admin"
import Empleado from "../screens/Empleado"
import UserRouter from "./UserRouter"

const IndexRouter = () => {
  const location = useLocation()

  const AdminRoute = withAuthenticationRequired(Admin, {
    onRedirecting: () => <div>Loading...</div>,
  })

  const EmpleadoRoute = withAuthenticationRequired(Empleado, {
    onRedirecting: () => <div>Loading...</div>,
  })

  const EgresadosRoute = withAuthenticationRequired(Egresados, {
    onRedirecting: () => <div>Loading...</div>,
  })

  return (
    <>
      {/* Condición para no mostrar NavBar en /promociones */}
      {location.pathname !== "/promociones2026" && <NavBar />}

      <Routes>
        <Route path="/*" element={<UserRouter />} />
        <Route path="/admin/*" element={<AdminRoute />} />
        <Route path="/empleado" element={<EmpleadoRoute />} />
        <Route path="/egresados/:id" element={<EgresadosRoute />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </>
  )
}

export default IndexRouter
